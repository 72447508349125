import '@livekit/components-styles';
import { Track } from 'livekit-client';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks
} from '@livekit/components-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from './state';
import { useNavigate } from 'react-router-dom';



const serverUrl = 'wss://warmcomm-mzfpklb3.livekit.cloud';

function getTokens( roomName, userName ) {
  // const userName = randomUsername();

  return fetch(`https://getlivekittoken-g5xpuhnf2q-uc.a.run.app/?roomname=${roomName}&username=${userName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).catch
    ((error) => console.error('Error:', error));
}

export default function App( ) {

  const [token, setToken] = useState();
  const { roomName } = useParams();
  const { user } = useAppState();
  let navigate = useNavigate();



  useEffect(() => {
    if (!user) {
      return;
    }
    getTokens(roomName, user?.email).then((response) => {
      console.log("fetching token")
      let token_str = response.text()
      token_str.then(res => setToken(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!token) {
    return <div>Loading...</div>;
  }


  return (
    <LiveKitRoom
      video={true}
      audio={true}
      token={token}
      serverUrl={serverUrl}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      style={{ height: '100vh' }}
      onDisconnected={() => {
        console.log('Disconnected');
        navigate("/");
      }}
    >
      {/* The RoomName component displays the room name and user count. */}
      
      <MyVideoConference />

      {/* The RoomAudioRenderer takes care of room-wide audio for you. */}

      <RoomAudioRenderer />

      {
      
      /* Controls for the user to start/stop audio, video, and screen 
      share tracks and to leave the room. */
      }

      <ControlBar />
      
    </LiveKitRoom>
  );
}

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  return (
    <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
}