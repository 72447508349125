import React, { createContext, useContext } from 'react';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { User } from 'firebase/auth';

export interface StateContextType {
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signOut?(): Promise<void>;
  emailLink:any,
  isLinkFromEmail:any,
  signWithLink:any,
  userEntity?: any;
  isAuthReady?: boolean;
  db: any;
  sendEmailLink?: any;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  //console.log("test - app context ");

  let contextValue = {
  } as StateContextType;

  contextValue = {
    ...contextValue,
    ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  };

  return <StateContext.Provider 
  value={{ 
    ...contextValue
   }}>
    {props.children}
    </StateContext.Provider>;

}

export function useAppState() {
  const context = useContext(StateContext);
  //console.log(context)

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
