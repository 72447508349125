import React from "react";
import SignOutButton from "../components/signout/SignOutButton";
import CreateRoom from "../components/createroom/CreateRoom";
import ListRooms from "../components/listrooms/ListRooms";
import { Card } from 'antd';

const LandingPage = () => {
    return (
        <div>
            <Card extra={<SignOutButton />}>
                <CreateRoom />
                <ListRooms />
            </Card>
        </div>
    )}

export default LandingPage;