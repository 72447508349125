import React from "react";
import { useAppState } from "../../state";
import { Card, Button, Space } from 'antd';
import { collection, doc, getDoc } from "firebase/firestore";
import DeleteRoom from "../deleteroom/DeleteRoom";


const GetRoom = (props: { roomId: any, deletable: boolean}) => {

    const { db } = useAppState();
    const [ roomData, setRoomData ] = React.useState<any>({});
    const [ linkCopied, setLinkCopied ] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!props) return;

        const roomID  = props.roomId;
        const roomRef = collection(db, "rooms_meta");
        const docRef = doc(roomRef, roomID);
        const roomDoc = getDoc(docRef);
        roomDoc.then((doc) => {
            if (doc.exists()) {
                console.log("Document data:", doc.data());
                setRoomData({
                    id: doc.id,
                    ...doc.data()
                });
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        
    }, [props, db]);

    if (!props) return <></>;
    console.log("room data :  ", roomData, "deletable: ", props.deletable);
    const roomURL = window.location.href+ "room/"+roomData.id;
    return (
        roomData &&
        <Card 
            title={roomData.name} 
            style={{ margin: 24, marginLeft: 0}}
            extra={ props.deletable && <DeleteRoom roomID={roomData.id}/>}
        >
                <Space>
                <a href={ roomURL} > {roomURL} </a>
                <Button 
                    type='text'
                    disabled={linkCopied}
                    onClick={() => {
                        navigator.clipboard.writeText(roomURL)
                        .then(() => {
                            setLinkCopied(true);
                        })
                    }}>
                    {linkCopied ? "copied" : "copy link to share" }
            </Button>
        </Space>
      </Card>
    );
}

export default GetRoom;