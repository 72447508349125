import React from "react";
import { query, collection, orderBy, onSnapshot } from 'firebase/firestore';
import { useAppState } from '../../state';
import { User } from "firebase/auth";
import GetRoom from "../getroom/GetRoom";
import { Divider } from "antd";

const useGetAllRooms = () => {
    
        const { db } = useAppState();
        const [rooms, setRooms] = React.useState<any[]>([]);
        React.useEffect(() => {
            const q = query(collection(db, "rooms_meta"), orderBy("created_at", "desc"));
            const unsub = onSnapshot(q, (data) => {
                let newData = data.docs.map((doc) => ({ 
                    id: doc.id,
                    ...doc.data()
                })
                );
                setRooms(newData);
                console.log("Current data: ", data.docs.map((doc) => (doc.data()) ));
            });
            return unsub;
        }, [db]);
    
        return rooms;
    }
const useGetRooms = (userID: string | undefined) => {

    const [rooms, setRooms] = React.useState<any[]>([]);
    const { db } = useAppState();
    

    React.useEffect(  () => {
        if (!userID) return;
        console.log("useEffect");
        const q = query(collection(db, `users/${userID}/rooms`),  orderBy( "room_id", "asc"));

        const unsub = onSnapshot(q, (data:any) => {
            let newData = data.docs.map((doc:any) => ({ 
                id: doc.id,
                ...doc.data()
            })
            );
    
            setRooms(newData);
            console.log("Current data: ", data.docs.map((doc:any) => (doc.data()) ));
        });
        return unsub;

        // getChats(db, entityID).then((chatData) => setChats(chatData));

    }, [userID, db]);

    return rooms;
}


const ListRooms = () => {
    
    const { user } = useAppState();
    const rooms = useGetRooms((user as User)?.uid);
    const allRooms = useGetAllRooms();


   console.log("rooms ", rooms);
    return (<>
  
        <ul>
            {rooms.map((room) => (<>
                {room.id && <GetRoom roomId={room.id} deletable />}
            </>
               
               
            ))}
        </ul>

        <Divider />
        <ul>
            <h4> rooms created by others </h4>
            {allRooms.filter(
                e => !rooms.map(e => e.id).includes(e.id)
            ).map((room) => (<>
                {room.id && <GetRoom roomId={room.id} deletable={false} />}
            </>
               
               
            ))}
        </ul>
        </>
    );
}
export default ListRooms;