import React from "react";
import { useAppState } from '../../state';
import { User } from "firebase/auth";
import { Button } from 'antd';
import { doc, deleteDoc } from "firebase/firestore";
import { DeleteOutlined } from '@ant-design/icons';


const DeleteRoom = (room:any) => {

    const { db, user } = useAppState();
    console.log("room ", room);
    if (!room) return <></>;

    return (
        <Button 
        icon={<DeleteOutlined />}
        type="text"
        onClick={() => {
            deleteDoc(doc(db, "rooms_meta", room.roomID));
            deleteDoc(doc(db, `users/${(user as User)?.uid}/rooms`, room.roomID));
        }} />
    );
}


export default DeleteRoom;