import React from "react";
import { Button, Form, Input } from 'antd';
import { useAppState } from '../../state';
import { addDoc, collection, setDoc, doc } from "firebase/firestore";
import { User } from "firebase/auth";
import FormItem from "antd/es/form/FormItem";

const CreateRoom = () => {

    const { db, user } = useAppState();
    const [ roomName, setRoomName ] = React.useState<string>("");
    const [ creating, setCreating ] = React.useState<boolean>(false);
    const addRoomToUser = async (roomId: string) => {
        // Add a new document with a generated id.
        await setDoc(doc(db, "users", (user as User)?.uid, "rooms", roomId), {
            room_id: roomId
        });
    }
    const createRoom = async () => {
        setCreating(true);
        const docRef = await addDoc(collection(db, "rooms_meta"), {
            name: roomName,
            created_at: new Date(),
            creator: (user as User)?.uid
        });
        console.log("Document written with ID: ", docRef.id);
        addRoomToUser(docRef.id);
        setRoomName("");
        setCreating(false);
    }   

    return (
        <div style={{
            marginRight: 32,
            marginLeft: 32,
            marginTop: 24,
        }}>
        <h4>create room</h4>
        {creating && <p>Creating room...</p>}
        {
            !creating && <Form>

                <FormItem>
                    <Input 
                        placeholder="enter room Name"
                        type="text" value={roomName} onChange={(e) => setRoomName(e.target.value)} />
                </FormItem>

                <Button 
                    onClick={createRoom}
                    type = "primary"
                    disabled={roomName.length < 3}
                >Create Room</Button>
            </Form>

        }
        </div>
        
    );
}

export default CreateRoom;
