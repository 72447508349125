import React from "react";
import {  Navigate } from "react-router-dom";
import { useAppState } from "./state";

const ProtectedRoute = ({  children }) => {

    const { isAuthReady, user } = useAppState();
    console.log("user", user)
    if (!isAuthReady ) {
        return null;
    }
    if (!user) {
        console.log("redirecting to signin")
        return <Navigate to="/signin" replace/>
    }
  
    return children;
    
};

export default ProtectedRoute;