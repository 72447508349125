import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useAppState } from '../state';
import { useNavigate } from 'react-router-dom';
import { Card, Row } from 'antd';
// import useSendMessage from '../../hooks/monitoring/useSendMessage';


const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string | null>(null);
    const [linkfromemail, setLinkFromEmail] = useState<boolean | null>(null);
    const [authError, setAuthError] = useState<Error | null>(null);
    const [message, setMessage] = useState<string | null>("no signup required, just enter your email and we will send you a link to sign in.");
    const [successfullySent, setSuccessfullySent] = useState<boolean | null>(null);
  const { user, isLinkFromEmail, emailLink, signWithLink  } = useAppState();

  useEffect(() => {
    // Check if the user opened a sign-in email link
    if (isLinkFromEmail(window.location.href)) {
        
      console.log("YES : the is link from email");
      // Get the email address from local storage or a query parameter
      const storedEmail =  localStorage.getItem('emailForSignIn');
      const urlParams = new URLSearchParams(window.location.search);
      const emailFromLink = urlParams.get('email');
      setEmail(emailFromLink || storedEmail); 
      setLinkFromEmail(true);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        console.log("linkfromemail ", linkfromemail);
        if (linkfromemail && !email) {
            const enteredEmail = prompt('Please provide your email for confirmation:');
            setEmail(enteredEmail);
            return;
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkfromemail]);

    // Prompt the user for their email if not available


    useEffect(() => {
        console.log("email ", email);
        if (email) {

          // Complete the sign-in process
          signWithLink(email, window.location.href)
            .then((result:any) => {

              console.log("result ", result);

              // User is signed in successfully.
              // Remove the email from storage
              // localStorage.removeItem('emailForSignIn');
              // Redirect the user (optional)
              // window.location.href = '/dashboard'; // Example redirect

            })
            .catch((error:any) => {
            
              // Handle sign-in errors
              console.error('Error signing in with email link:', error);
              
            }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [email]);
    // Complete the sign-in process

  let navigate = useNavigate();

  // console.log("user ", user);

  // useSendMessage(db, {"message": "login page loaded"});

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setAuthError(null);
    emailLink(values.email)
      .then(() => {
        console.log("push to homepage");
        setMessage("You will now receive an email from noreply@warmcomm-62913.firebaseapp.com. Please click the link in the email to sign in.");
        setSuccessfullySent(true);
        // navigate("/");
        }
        )
        .catch((err: React.SetStateAction<Error | null>) => {
        console.log('error in login page', err);
        setAuthError(err);
        })
    };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (user) {
    return null;
  }

  return (<div style={{
    margin: 8,
    height: '100vh',
  }}>
    <Card 
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "100%",
        }}
        bordered={false} >

      <Row>

        {
            !successfullySent && !linkfromemail && 
        
      <Form
        name="basic"
        
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
            label="email"
            name="email"
            rules={[{ 
            required: true, 
            message: 'Please input your email!' 
            }]}
        >
            <Input  />
        </Form.Item>
        {
            authError && <p>
            {authError.message}
            </p>
        }
        {
            message && <p>
            {message}
            </p>
        }

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" >
          Send singin link
        </Button>
      </Form.Item>

    </Form>
    }
        {
            successfullySent && <p>
      
            {message}
            </p>
        }
 
    </Row>
    </Card>
    </div>
  );
};



export default LoginPage;

