import { Button }   from 'antd';
import { useAppState } from '../../state';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const SignOutButton = () => {
  const { signOut } = useAppState();
  let navigate = useNavigate();

  const handleSignOut = () => {
    signOut?.()
      .then(() => {
        navigate("/signin");
      });
  };

  return (
    <Button onClick={handleSignOut}>Sign Out</Button>
  );
};

export default SignOutButton;