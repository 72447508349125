import { useCallback, useEffect, useState } from 'react';
import {initializeApp} from 'firebase/app';
import { 
  getAuth, 
  onAuthStateChanged, 
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  User,
  Auth
 } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

import 'firebase/storage';

const firebaseConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};


// setPersistence(auth, inMemoryPersistence);

export default function useFirebaseAuth() {

  const [user, setUser] = useState<User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [db, setDB] = useState<any>(null);
  const [storage, setStorage] = useState<any>(null);
  const [functions, setFunctions] = useState<any>(null);
  //const [auth, setAuth] = useState<Auth | null>(null);

  useEffect(() => {

    initializeApp(firebaseConfig);
    const auth = getAuth();

    onAuthStateChanged(auth, (updatedUser) => {
      setDB(getFirestore());
      console.log("updatedUser", updatedUser);
      if (updatedUser) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
        
          setUser(updatedUser);
          setStorage(getStorage());
          setFunctions(getFunctions());

        } else {
          // User is signed out
          // ...
        }

        setIsAuthReady(true);
      }

    );


  }, []);


  const emailLink = useCallback((email: string) => {
    initializeApp(firebaseConfig);
    const auth = getAuth();
    return sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true,
    });
  }, []);

  const isLinkFromEmail = useCallback((href:string) => {
    initializeApp(firebaseConfig);
    const auth = getAuth();
    console.log("isSignInWithEmailLink", href);


    return isSignInWithEmailLink(auth, href);

  }, []);

  const signWithLink = useCallback((email:string, href:string) => {
    initializeApp(firebaseConfig);
    const auth = getAuth();
    console.log("signInWithEmailLink", href)
    return signInWithEmailLink(auth, email, href);
  }, []);

  // MOVE OUT ::
  const signOut = useCallback(() => {
    initializeApp(firebaseConfig);
      const auth:Auth = getAuth();
      return auth.signOut().then(() => {
          setUser(null);
        });
  }, []);

  return {
    user,
    signOut,
    emailLink,
    isLinkFromEmail,
    signWithLink,
    isAuthReady,
    db,
    functions,
    storage,
  };
}


/*

  useEffect(() => {
    if (user !== null) {
      const entityRef = firebase.firestore().doc(`entities/${user.uid}`);
      const unsubscribe = entityRef.onSnapshot((doc: any) => {
        let entityFields: EntityInterface = { ...doc.data(), id: doc.id };
        setUserEntity(entityFields);
        // console.log('doc', entityFields);
      });

      return () => {
        try {
          unsubscribe();
        } catch (e) {
          console.log('error in useGetEntityDetails:', e);
        }
      };
    }
  }, [user]);


  // WTF ...
  const onboardEntries = (username: any, displayname: any, photourl: any, description: any) => {
    console.log(username, displayname, photourl, description);

    // @ts-ignore
    firebase.auth().currentUser?.updateProfile({ displayName: displayname, photoURL: photourl });
    // @ts-ignore
    let userRef = db.collection('users').doc(user?.uid);

    userRef
      .get()
      .then((docSnapshot: any) => {
        if (docSnapshot.exists) {
          userRef.update({
            username,
            displayname,
            description,
            id: user?.uid,
            photoURL: photourl,
            mentor: false,
          });
        } else {
          userRef.set({
            username,
            displayname,
            description,
            id: user?.uid,
            photoURL: photourl,
            mentor: false,
          });
        }
      })
      .catch((e: any) => console.log('error', e));
  };

  */